import { useState } from 'react';
import { ReactComponent as Lang } from '../../assets/icons/lang.svg';
import { ReactComponent as List } from '../../assets/icons/list.svg';
import { ReactComponent as Megaphone } from '../../assets/icons/megaphone.svg';
import { ReactComponent as CallServer } from '../../assets/icons/callserver.svg';

import wonsanji from '../../assets/icons/wonsanji.png';
import list from '../../assets/icons/orderlist.png';
import excute from '../data/pos/excute';
import Loader from '../../components/Loader';
import Notice from './IconSection/Notice';
import Language from './IconSection/Language';
import Wonsanji from './IconSection/Wonsanji';
import constants from '../../settings/constants';
import TableCall from './IconSection/TableCall';
import OrderList from './IconSection/OrderList';
import CustomDialog from '../../components/CustomDialog';

const IconSection = ({ rdxInfo }) => {
  const [isLoading, setLoading] = useState(false);
  const [isLanguage, setLanguage] = useState(false);
  const [orderList, setOrderList] = useState(false);
  const [isTableCall, setTableCall] = useState(false);
  const [resultTitle, setResultTitle] = useState('');
  const [isNoticeDialog, setNoticeDialog] = useState(false);
  const [isResultDialog, setResultDialog] = useState(false);
  const [isWonsanjiDialog, setWonsanjiDialog] = useState(false);

  const posName    = rdxInfo.shopInfo.posName;
  const isCallList = rdxInfo.itemInfo.tableCallList?.length;
  const isCallBell = (isCallList && (posName === 'UNIONPOS')) ? true : false;
  const isNotice = rdxInfo.shopInfo.noticeOptions?.useNotice;
  const isWonsanji = rdxInfo.shopInfo.noticeOptions?.origin;
  const type = 'OrderList';

  const tablCallProps = { rdxInfo, setTableCall, setResultTitle, setResultDialog }
  
  const onTableCall = () => { setTableCall(true) }
  const onNoticeClick = () => { setNoticeDialog(true) }
  const onLanguageClick = () => { setLanguage(true) }
  const onWonsanjiClick = () => { setWonsanjiDialog(true) }
  const callOrderList = async () => {
    const result = await excute({ rdxInfo, posName, type, setLoading });
    
    setOrderList(result);
  }
  
  if(isLoading)
    <Loader />
  return(
    <div className="icons">
      { isWonsanji && <img className='icon-common' src={wonsanji} width={48} onClick={onWonsanjiClick}/>}
      { isWonsanjiDialog && <Wonsanji rdxInfo={rdxInfo} visible={setWonsanjiDialog}/> }
      { isNotice && <Megaphone className='icon-common' width={constants.icon} onClick={onNoticeClick} />}
      { isNoticeDialog && <Notice rdxInfo={rdxInfo} visible={setNoticeDialog} /> }
      { <Lang className='icon-common' width={constants.icon} onClick={onLanguageClick} />}
      { isLanguage && <Language rdxInfo={rdxInfo} setLanguage={setLanguage} /> }
      { isCallBell && <CallServer className='icon-common' width={constants.icon} onClick={onTableCall} />}
      { isTableCall && <TableCall { ...tablCallProps } />}
      {/* { <List className='icon-common' width={constants.icon} onClick={callOrderList} />} */}
      { <img src={list} width={constants.icon} onClick={callOrderList} />}
      { orderList?.status && <OrderList rdxInfo={rdxInfo} orderInfo={orderList} /> }
      <CustomDialog
        visible={isResultDialog}
        sysMsg={rdxInfo.systemMsg}
        onOk={()=>setResultDialog(false)}
      >
        <div>{resultTitle}</div>
      </CustomDialog>
    </div>
  )
}

export default IconSection;