export const ErrorMsg = {
  notFound: "죄송합니다.\nNFC 태그 또는 QR코드로\n다시 접속해주세요.",
  authErr: '잘못된 접근 방식입니다. 다시 시도해주세요.',
  getItemInfo: '메뉴 정보를 가져오는데 실패 했습니다.\n잠시 후 다시 시도해 주세요.',
  getItemList: '메뉴 목록을 불러오는데 실패 했습니다.\n잠시 후 다시 시도해 주세요.',
  getShopInfo: '가맹점 정보를 가져오는 실패 했습니다.',
  tableNo: '해당 테이블이 존재하지 않습니다.',
  tokenErr: '잘못된 접근 방식입니다. 다시 태깅해주세요.',
  orderFail: "주문실패",
  loginError: '아이디와 비밀번호를\n다시 확인 후 시도해주세요.',
  userGetError: '아이디를 찾을 수 없습니다.',
  orderListError: '주문내역을 가져오는데 실패했습니다.',
}

export const tel = '1588-0325';
export const brandName = "스마트오더 '오늘'";

export const TabletUI = {
  tableNo: '테이블 번호 선택',
  cs: '문의 : 1588-0325',
  title: '"오늘" Tablet 로그인',
  logout: '로그아웃 하시겠습니까?',
  okBtnTitle: '로그인',
  phUsername: '아이디',
  phPassword: '비밀번호',
}

export const systemMsgMap = {
  "KO" : {
    ui: {
      addBtn: '+ 더 담으러 가기',
      backBtn: '뒤로가기',
      best: '대표메뉴',
      cancel: '취 소',
      callStaff: '직원호출',
      cart: '주문하러 가기',
      cartTitle: '장바구니',
      changeLang: '언어 변경',
      essential: '필수',
      extra: '선택',
      goCart: '담기',
      goOrder: ' • 주문하기',
      hot: '인기메뉴',
      inStore: '매장식사',
      items: '메뉴: ',
      langName: '한',
      new: '신메뉴',
      notice: '공지사항',
      ok: '확 인',
      orderList: '주문내역',
      orderListTitle1: ' 총 주문내역',
      orderListTitle2: ' 번 테이블 총 주문내역',
      orderTime: '주문시간: ',
      phoneTitle: '주문 확인용\n전화번호 입력',
      price: '가격',
      qty: '수 량 : ',
      tabletTotalPrice: '총 ',
      tabletAddButton: '더 담기',
      tableNick: '번 테이블',
      totalCnt1: '',
      totalCnt2: '개 선택 필수',
      totalPrice: '합계',
      warnning: 'Warnning!!',
      warnPhone: '(전화번호는 주문 알림 목적으로만 사용됩니다. 전화번호 입력에 동의하지 않으면 취소해 주세요.)',
      won: '원',
      wrongPhone: '잘못된 번호입니다.\n다시 입력해 주세요.',
    },
    dialog: {
      emptyCart: '카트가 비었습니다.',
      oneMin1: '주문종료 1분전',
      oneMin2: '만약 1분이 지나 주문이 안되면\n다시 태깅후 주문해주세요.',
      orderTimeErr: '주문시간이 초과 되었습니다.\n다시 태깅해 주세요.',
      callSuccess: '직원을 호출하였습니다.',
      callFailure: '직원 호출에 실패 했습니다.\n다시 시도해주세요.',
      orderComplete: '주문이 완료되었습니다.\n감사합니다.',
      orderFail: '주문이 실패하였습니다.\n다시 태깅 후 주문해주세요.',
      orderSuccess: '주문 완료',
      tableLocked: '포스에서 사용중인 테이블 입니다.\n직원에게 문의해 주세요.',
      posDisconnect: '포스와 서버가 연결해제 되었습니다.\n직원에게 문의해 주세요.',
      warnning: '주의!',
      wonsanji: '원산지 표시',
      logout: '로그아웃 하시겠습니까?',
      closebrowser: '브라우저를 종료하시겠습니까?',
      jwtError: '주문시간이 초과했습니다.\n 다시 태깅 후 주문해주세요.',
    },
  },
  "EN" : {
    ui: {
      addBtn: '+ Continue Shopping',
      backBtn: 'Back',
      best: 'Signature',
      cancel: 'Cancel',
      callStaff: 'Call Server',
      cart: 'Place Order',
      caertTitle: 'Cart',
      changeLang: 'Change Language',
      essential: 'Required',
      extra: 'Optional',
      goCart: 'Add to Cart',
      goOrder: 'Order',
      hot: 'Hot',
      inStore: 'Dine in',
      items: 'Cart Items: ',
      langName: '',
      new: 'New',
      notice: 'Notice',
      ok: 'O K',
      orderList: 'Order list',
      orderListTitle1: 'Total order list',
      orderListTitle2: 'Total order list',
      orderTime: 'Order time: ',
      phoneTitle: 'Cell Phone Number',
      price: 'Price',
      qty: 'Quantity : ',
      tabletTotalPrice: 'Total: ',
      tabletAddButton: 'Continue Shopping',
      tableNick: 'Table : ',
      totalCnt1: 'Please Choose',
      totalCnt2: ' items.',
      totalPrice: 'Total',
      warnning: 'Warnning!!',
      warnPhone: 'Your phone number will only be used for order notifications. If you do not agree to provide it, please cancel.',
      won: '₩',
      wrongPhone: 'Invalid number.\nPlease enter it again.',won: '₩',
    },
    dialog: {
      emptyCart: 'Your cart is empty.\nPlease add items to proceed with your order.',
      oneMin1: 'Please order within 1 minute.',
      oneMin2: `If the order is not placed within 1 minute,
  please re-tag and place your order`,
      orderTimeErr: 'Sorry, but the order time has exceeded.\nPlease re-tap.',
      callSuccess: 'The Server has been called.',
      callFailure: 'Failed to call the server.\nPlease try again.',
      orderComplete: 'The order has been successfully placed.',
      orderFail: 'The order was not successful.\nPlease re-tap and order.',
      orderSuccess: 'Order complete',
      tableLocked: 'The order was not successful.\nYou can ask the staff for information.',
      posDisconnect: 'POS and server have been disconnected.\nYou can ask the staff for information.',
      warnning: 'Order Failed!',
      wonsanji: 'Origin Labeling',
      logout: 'Do you want to log out?',
      jwtError: "The order time has expired. Please tap again and place your order.",
      closebrowser: 'Do you want to close the browser?'
    },
  },
  "JP": {
    ui: {
      addBtn: '+ 買い物を続ける',
      backBtn: '戻る',
      best: 'シグネチャーメニュー',
      callStaff: 'Call Server',
      cancel: 'キャンセル',
      cart: '注文する', //'ショッピングカート',
      cartTitle: 'カート',
      changeLang: '言語を変更する',
      essential: '必須選択',
      extra: '任意',
      goCart: 'カートに入れる',
      goOrder: '注文する',
      hot: '人気メニュー',
      inStore: '店内食事',
      items: 'カート内のメニュー: ',
      langName: '',
      new: '新メニュー',
      notice: 'お知らせ',
      ok: 'O K',
      orderList: '注文履歴',
      orderListTitle1: '注文合計',
      orderListTitle2: '注文合計',
      orderTime: '注文時間: ',
      phoneTitle: '注文確認電話番号',
      price: '価格',
      qty: '数量 : ',
      tabletTotalPrice: '合計: ',
      tabletAddButton: '買い物を続ける',
      tableNick: 'Table : ',
      totalCnt1: '必ず',
      totalCnt2: 'つを選んでください',
      totalPrice: '合計',
      warnning: '注意!!',
      warnPhone: '電話番号は注文通知の目的でのみ使用されます。\n電話番号の入力に同意しない場合は、キャンセルしてください。',
      won: '₩',
      wrongPhone: '無効な番号です。\nもう一度入力してください。',
    },
    dialog: {
      emptyCart: 'カートは空です.\n注文を続けるには商品を追加してください.',
      oneMin1: '1分以内にご注文ください.',
      oneMin2: `1分以内にご注文がない場合、
      再度タグをスキャンしてご注文ください`,
      orderTimeErr: '申し訳ありませんが、注文時間が過ぎてしまいました.\nもう一度タップしてください.',
      callSuccess: 'スタッフを呼びました.',
      callFailure: 'スタッフを呼ぶことができませんでした.\nもう一度試してみてください.',
      orderComplete: '注文が正常に完了しました.',
      orderFail: '注文が成功しませんでした.\nもう一度タップして注文してください.',
      orderSuccess: '注文完了',
      tableLocked: 'このテーブルはPOSで使用中です,\nスタッフにお尋ねいただけます。',
      posDisconnect: 'POSとサーバーの接続が切断されました,\nスタッフにお尋ねいただけます。',
      warnning: '注文が失敗しました!',
      wonsanji: '原産地表示',
      logout: 'ログアウトしますか？',
      jwtError:  "注文時間が過ぎました。\nもう一度タップして、注文してください。",
      closebrowser: 'ブラウザを閉じますか？'
    },
  },
  "CN": {
    ui: {
      addBtn: '+ 继续购物',
      backBtn: '返回',
      best: '招牌菜单',
      callStaff: '呼叫服务员',
      cancel: '取消',
      cart: '去下单', //'购物车',
      cartTitle: '购物车',
      changeLang: '更改语言',
      essential: '必选项',
      extra: '可选',
      goCart: '加入购物车',
      goOrder: '点菜',
      hot: '热门菜单',
      inStore: '堂食',
      items: '购物车内的菜单: ',
      langName: '',
      new: '新菜单',
      notice: '公告',
      ok: '确定',
      orderList: '订单记录',
      orderListTitle1: '总订单记录',
      orderListTitle2: '总订单记录',
      orderTime: '下单时间: ',
      phoneTitle: '订单确认电话',
      pricer: '价格',
      qty: '数量 : ',
      tabletTotalPrice: '总: ',
      tabletAddButton: '继续购物',
      tableNick: 'Table : ',
      totalCnt1: '请必须选择',
      totalCnt2: '个项目',
      totalPrice: '总计',
      warnning: '警告!!',
      warnPhone: '电话号码仅用于订单通知。\n如果您不同意提供电话号码，请取消。',
      won: '₩',
      wrongPhone: '号码无效。请重新输入。',
    },
    dialog: {
      emptyCart: '您的购物车是空的。\n请添加商品以继续下单。',
      oneMin1: '请在1分钟内下单。',
      oneMin2: '如果一分钟内没有下单, 请重新点击并下单。',
      orderTimeErr: '订单时间已超时，请重新点击。',
      callSuccess: '服务员已经被呼叫了。',
      callFailure: '呼叫服务员失败，请重试。',
      orderComplete: '订单已成功下单。',
      orderFail: '订单未成功，请重新点击下单。',
      orderSuccess: '订单完成',
      tableLocked: '订单未成功，你可以向工作人员询问情况。',
      posDisconnect: 'POS机和服务器已断开连接, 你可以向工作人员询问情况。',
      warnning: '订单失败!',
      wonsanji: '原产地标识',
      logout: '你要登出吗？',
      jwtError: "订单时间已过，\n请再次点击后下单。",
      closebrowser: '您要关闭浏览器吗？'
    },
  },
}