import { systemMsgMap } from "./texts";

import constants from "./constants";

export const supportedLangs = ['KR', 'EN', 'JP', 'CN'];


export function setLanguageLocale(lang) {
  if(lang === 'KR') lang = 'KO'
  else if(!systemMsgMap[lang]) lang = 'KO'
  
  return lang;
}

export function setSystemLanguage(lang ) {
  if(lang === 'KR') lang = 'KO'
  let temp = systemMsgMap[lang || 'KO'];
  if(!temp){
    temp = systemMsgMap["KO"];
    lang = "KO";
  }
  
  temp = {...temp, langName: constants.langIconMap[lang]};

  return temp;
}

export function changeSelectedLangs(rdxInfo, selectedLang){
  const rawDatas = rdxInfo.itemInfo;
  const rawItemList = rdxInfo.itemList;

  let itemList = Object.assign([], rawItemList);
  let grpList = Object.assign([], rawDatas.GrpList);
  let dispGrpList = Object.assign([], rawDatas.DisplayGrpList);
  let choiceList = Object.assign([], rawDatas?.ChoiceItemList);

  grpList = changeGrpLang(grpList, selectedLang);
  dispGrpList = changeGrpLang(dispGrpList, selectedLang);
  itemList = changeItemLang(itemList, selectedLang);
  choiceList = changeItemLang(choiceList, selectedLang);
  
  const result = {
    ...rawDatas, 
    GrpList : grpList,
    DisplayGrpList: dispGrpList,
    ItemList: itemList,
    ChoiceItemList: choiceList
  }
  
  return result;
}
function changeItemLang(itemList, selectedLang){
  itemList = itemList.map((item) => {
    let newItem = Object.assign({}, item);
    newItem = changeItemName(newItem, selectedLang);
    return newItem;    
  })
  return itemList;
}
function changeGrpLang(grpList, selectedLang) {
  grpList = grpList.map((grp) => {
    let newGrp = Object.assign({}, grp);
    newGrp = changeGrpName(newGrp, selectedLang);
    newGrp.menus = grp.menus.map((item) => {
      let newItem = Object.assign({}, item);
      item = changeItemName(newItem, selectedLang);
      item = changeDescript(item, selectedLang);
      return item;
    })
    return newGrp;
  });
  return grpList;
}
function changeItemName(item, lang){
  let name = {}
  switch (lang) {
    case 'KO':
    case 'KR':
      name = item.nameLang?.KR;
      break;
    case 'EN':
      name = item.nameLang?.EN;
      break;
    case 'JA':
    case 'JP':
      name = item.nameLang?.JP;
      break;
    case 'CN':
    case 'ZH':
    case 'CH':
      name = item.nameLang?.CN;
      break;
    default:
      break;
  }
  name ? item.itemName2 = name : item.itemName2 = item.backupName;

  return item;
}
function changeGrpName(grp, lang){
  let name = {}
  switch (lang){
    case 'KR':
    case 'KO':
      name = grp.nameLang?.KO;
      break;
    case 'EN':
      name = grp.nameLang?.EN;
      break;
    case 'JP':
    case 'JA':
      name = grp.nameLang?.JP;
      break;
    case 'CN':
    case 'ZH':
    case 'CH':
      name = grp.nameLang?.CN;
      break;
    default :
      break;
  }
  name ?
  grp.displayName = name : 
  grp.displayName = grp.backupName;
  
  return grp;
}
function changeDescript(item, lang){
  
  let desc = {}
  switch (lang) {
    case 'KO':
    case 'KR':
      desc = item.posDescriptionLang?.KR || item.descriptionLang?.KR;
      break;
    case 'EN':
      desc = item.posDescriptionLang?.EN || item.descriptionLang?.EN;
      break;
    case 'JA':
    case 'JP':
      desc = item.posDescriptionLang?.JP || item.descriptionLang?.JP;
      break;
    case 'CN':
    case 'ZH':
    case 'CH':
      desc = item.posDescriptionLang?.CN || item.descriptionLang?.CN;
      break;
    default:
      break;
  }
  if(desc)
    item.description = desc;

  return item;
}