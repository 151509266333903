import CustomDialog from "../../../components/CustomDialog";

const Wonsanji = (props) => {
  const { rdxInfo, visible } = props;

  const onOk = () => visible(false);

  return(
    <CustomDialog
      visible={true}
      sysMsg={rdxInfo.systemMsg}
      title={rdxInfo.systemMsg.dialog.wonsanji}
      onOk={onOk}
    >
      <div>{rdxInfo.shopInfo.noticeOptions?.origin}</div>
    </CustomDialog>
  )
}

export default Wonsanji;